  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-Thin.ttf);
      font-weight: 100;
      font-style: normal;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-ThinItalic.ttf);
      font-weight: 100;
      font-style: italic;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-Light.ttf);
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-LightItalic.ttf);
      font-weight: 300;
      font-style: italic;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-Regular.ttf);
      font-weight: 400;
      font-style: normal;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-Italic.ttf);
      font-weight: 400;
      font-style: italic;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-Medium.ttf);
      font-weight: 500;
      font-style: normal;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-MediumItalic.ttf);
      font-weight: 500;
      font-style: italic;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-Bold.ttf);
      font-weight: 700;
      font-style: normal;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-BoldItalic.ttf);
      font-weight: 700;
      font-style: italic;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-Black.ttf);
      font-weight: 900;
      font-style: normal;
  }

  @font-face {
      font-family: "Roboto";
      src: url(../assets/fonts/Roboto-BlackItalic.ttf);
      font-weight: 900;
      font-style: italic;
  }

  :root {
      --cui-body-font-size: 0.85rem;
  }

  body {
      margin: 0;
      font-family: "Roboto";
      font-size: small;
      overflow: hidden;
  }

  p {
      margin: 0;
  }

  .page-link {
      color: rgba(60, 56, 52, 1) !important;
  }

  .form-control:focus {
      color: rgba(60, 56, 52, 1);
      background-color: #fff;
      border-color: rgba(60, 56, 52, 1);
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(60, 56, 52, 0.5);
  }

  .react-daterange-picker__wrapper {
      border-style: none !important;
      min-width: 100% !important;
  }

  .react-daterange-picker {
      min-width: 100% !important;
  }

  .react-daterange-picker__inputGroup {
      text-align: center !important;
  }

  .react-daterange-picker__inputGroup__input {
      color: rgba(60, 56, 52, 1) !important;
      font-size: small !important;
  }

  .react-daterange-picker,
  .react-daterange-picker *,
  .react-daterange-picker *:before,
  .react-daterange-picker *:after {
      color: rgba(60, 56, 52, 1) !important;
      font-size: small !important;
  }

  .react-daterange-picker--disabled,
  .react-daterange-picker--disabled *,
  .react-daterange-picker--disabled *:before,
  .react-daterange-picker--disabled *:after {
      color: #768192 !important;
      font-size: small !important;
  }

  .react-daterange-picker react-daterange-picker--closed react-daterange-picker--enabled font-xs font-weight-normal {
      min-width: 100% !important;
  }

  .react-daterange-picker--disabled {
      height: 100% !important;
      background-color: #d8dbe0 !important;
  }

  .cor-vermelha {
      background-color: rgba(255, 0, 0, 0.5);
  }

  .fonte-cinza {
      color: rgba(60, 56, 52, 1);
  }